import { Component, Inject, OnInit } from '@angular/core';
import { take } from 'rxjs/operators';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  FirebaseAccountPaymentService,
  IFirebasePaymentMethod,
  IUserPlan,
  UserPlansService
} from '@ztarmobile/zwp-service-backend';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { ToastrHelperService } from 'src/services/toast-helper.service';
import { PlatformLocation } from '@angular/common';
import { ACCOUNT_ROUTE_URLS } from '@app/app.routes.names';
import { AppState } from '@app/app.service';
import { UserDataService } from '@services/user-data.service';
import { UserAccountService } from '@ztarmobile/zwp-service-backend-v2';

export class G2gPaymentModalContext {
  public paymentMethodId: string;
  public userPlan: IUserPlan;
  public customClass?: string;
  public accountSettingsRoute?: string;
}

@Component({
  selector: 'app-manage-payment-specific-modal',
  templateUrl: './manage-payment-specific-modal.component.html'
})
export class ManagePaymentSpecificModalComponent implements OnInit {
  public context: any;
  public setDefaultRequest: boolean;
  public methodsList: IFirebasePaymentMethod[] = [];
  public expiredMethodsList: IFirebasePaymentMethod[] = [];
  public nonExpiredmethodsList: Array<IFirebasePaymentMethod> = [];
  public selectedMethodId: string;
  public displayRequiredMsg = false;
  public currentYear;
  public currentMonth;

  private paymentMethodsListSubscription: Subscription;
  private currentDate: Date;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private firebaseAccountPaymentService: FirebaseAccountPaymentService,
    private toastHelper: ToastrHelperService,
    private userAccountServiceV2: UserAccountService,
    private userPlanService: UserPlansService,
    private router: Router,
    public dialog: MatDialogRef<G2gPaymentModalContext>,
    private location: PlatformLocation,
    private appState: AppState,
    private userDataService: UserDataService) {
    location.onPopState(() => { this.beforeClose(); this.dialog.close(); });
    this.context = data;
  }

  ngOnInit(): void {
    this.currentDate = new Date();
    // we have to add 1 since getMonth start array from index 0
    this.currentMonth = this.currentDate.getMonth() + 1;
    this.currentYear = this.currentDate.getFullYear();
    this.userPlanService.getUserPlan(this.context?.userPlan?.id).then((userPlan) => {
      this.context.userPlan = userPlan;
      this.selectedMethodId = this.context?.paymentMethodId;
    });

    this.paymentMethodsListSubscription = this.firebaseAccountPaymentService.paymentMethodsList.pipe(take(1)).subscribe((methods) => {
      this.methodsList = methods;
      if (this.methodsList?.length > 0) {
        this.currentMonth = this.currentMonth.toString();
        this.currentYear = this.currentYear.toString().slice(-2);
        if (this.currentMonth?.length === 1) {
          this.currentMonth = `0${this.currentMonth}`;
        }
        this.methodsList.map(method => {
          if (!!method && !!method?.expirationDate) {
            let cardExpMonth = method?.expirationDate?.substr(0, 2);
            let cardExpYear = method?.expirationDate?.substr(2, 2);
            // if card year less than currect year then its expired
            if (parseInt(cardExpYear, 10) < parseInt(this.currentYear, 10)) {
              this.expiredMethodsList.push(method);
              Object.assign(method, { expired: true });
            } else if (parseInt(cardExpYear, 10) <= parseInt(this.currentYear, 10) && parseInt(cardExpMonth, 10) < parseInt(this.currentMonth, 10)) {
              this.expiredMethodsList.push(method);
              Object.assign(method, { expired: true });
            } else {
              Object.assign(method, { expired: false });
              this.nonExpiredmethodsList.push(method);
            }
          }
        });
      } else {
        this.context.title = 'A saved card is required'
      }
    });
  }

  beforeClose(): void {
    if (!!this.paymentMethodsListSubscription) {
      this.paymentMethodsListSubscription.unsubscribe();
    }
    if (document.body.classList.contains('modal-open')) {
      document.body.classList.remove('modal-open');
    }
  }
  public closeDialog(methodId?: any): void {
    this.beforeClose();
    this.dialog.close(methodId);
  }
  public cardSelected(id): void {
    this.selectedMethodId = id;
    this.displayRequiredMsg = false;
  }
  public makePaymentMethodAsDefault(methodKey): void {
    if(!!methodKey) {
      if (this.setDefaultRequest) {
        return;
      }
      this.displayRequiredMsg = false;
      const userPlan = this.context?.userPlan;
      this.setDefaultRequest = true;
      this.appState.loading = true;
      this.userAccountServiceV2.updateDefaultPayment(userPlan?.mdn, {paymentId: methodKey, isDefault: true, planId: userPlan?.id}).then(() => {
        this.userDataService.callSyncApi(this.context?.userPlan?.id, this.context?.userPlan?.mdn).then(res => {
          this.setDefaultRequest = false;
          this.appState.loading = false;
          this.toastHelper.showSuccess('Default Payment Method Set, It May Take About 1 Minute Before The Account Getting The Effect.');
          if(!!res?.profileKey) {
            this.closeDialog({success: 'success', res});
          } else {
            this.closeDialog('error');
          }
        });
      }, (error) => {
        this.setDefaultRequest = false;
        this.appState.loading = false;
        this.toastHelper.showAlert(error?.error?.errors[0]?.message);
        this.closeDialog('error');
      });
    }  else {
      this.displayRequiredMsg = true;
    }

  }
  public goToSettings(): void {
    this.closeDialog();
    const params = {};
    params[ACCOUNT_ROUTE_URLS.PARAMS.MANAGE_CARDS] = true;
    this.router.navigate([this.context.accountSettingsRoute, params]);
  }
  public checkBoxLabel(address: IFirebasePaymentMethod): string {
    let addressLabel = '';
    if (!!address.brand) {
      addressLabel = address.brand;
    } else {
      if (!!address.alias) {
        addressLabel = address.alias;
      }
    }
    return addressLabel.toLowerCase();
  }
}
