<div class="banner-container">
  <div class="banners-swiper-container">
    <div *ngIf="!showValentinesBanner" class="banner-section">
      <picture class="banner-image-container">
        <source width="639" height="520" srcset='assets/img/banners/g2g-winter-banner-mobile.webp'
          media="(max-width: 639.9px)" type="image/webp">
        <source width="639" height="520" srcset='assets/img/banners/g2g-winter-banner-mobile.jpg'
          media="(max-width: 639.9px)" type="image/jpg">
        <source width="1024" height="500" srcset='assets/img/banners/g2g-winter-banner-tablet.webp'
          media="(max-width: 1024.9px)" type="image/webp">
        <source width="1024" height="500" srcset='assets/img/banners/g2g-winter-banner-tablet.jpg'
          media="(max-width: 1024.9px)" type="image/jpg">
        <source width="2000" height="500" srcset='assets/img/banners/g2g-winter-banner.webp' media="(min-width: 1025px)"
          type="image/webp">
        <source width="2000" height="500" srcset='assets/img/banners/g2g-winter-banner.jpg' media="(min-width: 1025px)"
          type="image/jpg">
        <img width="2000" height="500" src="assets/img/banners/Desktop.jpg" alt="Unlimited Talk, Text & Data for $25 "
          title="Unlimited Talk, Text & Data for $25" loading="eager">
      </picture>
      <div class="banner-content-container">
        <p class="warm-up smaller">Warm up your Winter with our best </p>
        <h1 class="header">UNLIMITED</h1>
        <!-- <p class="caption-text">Talk, Text, and Data for</p> -->
        <p class="subheader smaller">Talk, Text, and Data for</p>
        <div class="price">
          <p class="amount"><span class="dollar">$</span><span class="number">25</span></p>
          <p class="mo">/mo</p>
        </div>
        <button class="button primary" (click)="goToOfferLanding()" aria-label="Shop Today">Shop Now!</button>
      </div>
    </div>

    <div *ngIf="showValentinesBanner" class="banner-section valentines">
      <picture class="banner-image-container">
        <source width="639" height="520" srcset='assets/img/banners/valentines-home-banner-mobile.webp' media="(max-width: 639.9px)"
          type="image/webp">
        <source width="639" height="520" srcset='assets/img/banners/valentines-home-banner-mobile.jpg' media="(max-width: 639.9px)"
          type="image/jpg">
        <source width="1024" height="500" srcset='assets/img/banners/valentines-home-banner-tablet.webp' media="(max-width: 1024.9px)"
          type="image/webp">
        <source width="1024" height="500" srcset='assets/img/banners/valentines-home-banner-tablet.jpg' media="(max-width: 1024.9px)"
          type="image/jpg">
        <source width="2000" height="500" srcset='assets/img/banners/valentines-home-banner.webp' media="(min-width: 1025px)"
          type="image/webp">
        <source width="2000" height="500" srcset='assets/img/banners/valentines-home-banner.jpg' media="(min-width: 1025px)"
          type="image/jpg">
        <img width="2000" height="500" src="assets/img/banners/valentines-home-banner.jpg" alt="Unlimited Talk, Text & Data for $25 "
          title="Unlimited Talk, Text & Data for $25" loading="eager">
      </picture>
      <div class="banner-content-container">
        <p class="valentine-text smaller"> Happy Valentine’s Day</p>
        <h2 class="caption-text">$25/mo <span class="for">for</span></h2>
        <h1 class="header">UNLIMITED</h1>
        <h4 class="subheader">Talk, Text & Data</h4>
        <button class="button primary" (click)="goToOfferLanding()" aria-label="Shop Today">Shop Today!</button>
      </div>
    </div>
  </div>
</div>