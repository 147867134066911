import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { SUPPORT_ROUTE_URLS, SHOP_ROUTE_URLS, ACCOUNT_ROUTE_URLS, ROUTE_URLS, PHONES_SHOP_ROUTE_URLS, PLANS_SHOP_ROUTE_URLS } from '../../app/app.routes.names';
import { BUILD_VERSION, ENV_FIREBASE_CONFIG, INVISIBLE_CAPTCHA_ID } from 'src/environments/environment';
import { SimpleAuthService } from '@ztarmobile/zwp-services-auth';
import { AppState } from 'src/app/app.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EMAIL_PATTERN } from '@app/app.config';
import { NewsletterService } from '@ztarmobile/zwp-service-backend-v2';
import { InvisibleRecaptchaComponent } from '@widgets/invisible-recaptcha/invisible-recaptcha.component';
import { ToastrHelperService } from '@services/toast-helper.service';
import { FirebaseUserProfileService, IUser } from '@ztarmobile/zwp-service-backend';
import { filter, take } from 'rxjs';

@Component({
  selector: 'app-footer-main-navbar',
  templateUrl: 'footer-main-navbar.component.html',
  styleUrls: ['./footer-main-navbar.component.scss']
})
export class FooterMainNavbarComponent implements OnInit {
  @ViewChild('invisibleReCaptcha') invisibleReCaptcha: InvisibleRecaptchaComponent;
  public ROUTE_URLS = ROUTE_URLS;
  public SHOP_ROUTE_URLS = SHOP_ROUTE_URLS;
  public ACCOUNT_ROUTE_URLS = ACCOUNT_ROUTE_URLS;
  public SUPPORT_ROUTE_URLS = SUPPORT_ROUTE_URLS;
  public PHONES_SHOP_ROUTE_URLS = PHONES_SHOP_ROUTE_URLS;
  public PLANS_SHOP_ROUTE_URLS = PLANS_SHOP_ROUTE_URLS;
  public BUILD_VERSION = BUILD_VERSION;
  public SITE_ID = INVISIBLE_CAPTCHA_ID;
  public BUILD_DATE = new Date();
  public FIREBASE_CONF = ENV_FIREBASE_CONFIG;
  public isLoggedIn = false;
  public displayAcpSection = false;
  public newsletterForm: FormGroup;
  openedMenu: string;
  public captchaResponse: string;
  public captchaValid = false;
  public user: IUser = {} as IUser;
  public isSeconCaptcha = false;
  public currentYear = new Date().getFullYear();

  constructor(private simpleAuthService: SimpleAuthService,
    private appState: AppState, private formBuilder: FormBuilder,
    private newsLettersService: NewsletterService,
    public toastHelper: ToastrHelperService,
    private userProfileService: FirebaseUserProfileService) { }

  ngOnInit(): void {
    this.newsletterForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.pattern(EMAIL_PATTERN)]],
    });
    this.simpleAuthService.userState.subscribe((authState) => {
      this.isLoggedIn = !!authState && !authState.isAnonymous;
    });
    this.appState.displayAcpSectionObs.subscribe(res => {
      this.displayAcpSection = res;
    });
    this.appState.resetAndExecuteCaptcha.subscribe(response => {
      if (!!response && response?.action) {
        this.invisibleReCaptcha.resetReCaptcha();
        this.invisibleReCaptcha.execute(response?.action);
        this.isSeconCaptcha = false;
      }
    });
    this.appState.resetAndExecuteSecondCaptcha.subscribe(response => {
      if (!!response && response?.action) {
        this.invisibleReCaptcha.resetReCaptcha();
        this.invisibleReCaptcha.execute(response?.action);
        this.isSeconCaptcha = true;
      }
    });
  }


  toggleMenu(name: string) {
    this.openedMenu = this.openedMenu == name ? null : name;
  }
  public resolvedCaptcha(captchaResponse: string): void {
    this.captchaResponse = captchaResponse;
    this.captchaValid = !!captchaResponse;
    if(!this.isSeconCaptcha) {
      this.appState.resolvedCaptchaSubject.next(captchaResponse);
    } else {
      this.appState.resolvedSecondCaptchaSubject.next(captchaResponse);
    }
  }

  public async subscribeToNewsletter(): Promise<void> {
    this.newsletterForm.markAllAsTouched();
    const action = 'g2g_email_preference_subscription';
    this.invisibleReCaptcha.resetReCaptcha();
    this.invisibleReCaptcha.execute(action);
    const resolvedSubscription = this.invisibleReCaptcha.resolved.subscribe((captchaResponse: string) => {
      resolvedSubscription.unsubscribe();
      if (!!this.newsletterForm.valid && !!captchaResponse) {
        const enteredEmail = this.newsletterForm.controls.email.value;
        this.appState.loading = true;
        this.newsLettersService.subscribe({ email: enteredEmail }, 'email-preference', captchaResponse, action, true).then((res) => {
          this.appState.loading = false;
          if (!!this.isLoggedIn) {
            this.userProfileService.userProfileObservable.pipe(take(1), filter((user) => !!user)).subscribe((user) => {
              this.user = user;
              if (!!this.user && !!this.user?.email) {
                this.user.receiveMarketingMails = true;
                this.userProfileService.bffUpdateUserProfile(this.user);
              }
            });
          }
          this.toastHelper.showSuccess(`Success! You've been signed up to our email newsletter and offers!`);
          this.invisibleReCaptcha.resetReCaptcha();
          this.newsletterForm.reset();
        }, (error) => {
          this.appState.loading = false;
          if (!!error?.error && !!error?.error?.errors[0] && error?.error?.errors[0]?.code === '114') {
            this.toastHelper.showAlert(error?.error?.errors[0]?.message);
          } else {
            this.toastHelper.showAlert('There was an issue while processing your request. Please try again!');
          }
          this.invisibleReCaptcha.resetReCaptcha();
          this.newsletterForm.reset();
        })
      }
    });

  }

}
