<div class="modal">
  <div class="container model-container full {{context.customClass}}">
    <div class="modal-header">
      <button class="modal-close modal-toggle" (click)="closeDialog()" *ngIf="!setDefaultRequest"><i class="icon-close"></i></button>
      <h4 class="modal-heading">{{ this.context?.title }}</h4>
    </div>
    <div class="modal-body">
      <div class="modal-content">
        <ng-container *ngIf="nonExpiredmethodsList?.length > 0">
          <p class="note">Choose a different payment method below:</p>
          <div class="account-address-details-container highlight-card" *ngFor="let methodItem of nonExpiredmethodsList"
          (click)="cardSelected(methodItem.id)" [class.red-border]="!!displayRequiredMsg">
            <div class="custom-checkbox">
              <div class="checkbox">
                <input title="{{methodItem.alias}}" type="radio" [checked]="methodItem.id === selectedMethodId"
                  name="shippingAddress" [id]="methodItem.id" [value]="methodItem">
                <label [for]="methodItem.id"></label>
              </div>
            </div>
            <div class="details">
              <p class="smaller"><b>{{checkBoxLabel(methodItem)}}</b></p>
              <p class="smaller">Ending in {{methodItem.last4}},</p>
              <p class="smaller">Expiry date: {{methodItem.expirationDate | expirationDateFormat}}</p>
            </div>
          </div>
        </ng-container>
        <div *ngIf="nonExpiredmethodsList?.length <= 0 && expiredMethodsList?.length === 1" id="expired-card">
          <p id="expired-message">Your saved credit card has expired.</p>
          <p id="expired-links">Kindly <a (click)="goToSettings()">update your payment method</a>, or <a
              (click)="goToSettings()">add a new one</a>.</p>
        </div>
        <div *ngIf="nonExpiredmethodsList?.length <= 0 && expiredMethodsList?.length > 1" id="expired-cards">
          <p id="expired-message">Your saved credit cards have expired.</p>
          <p id="expired-links">Kindly <a (click)="goToSettings()">update your payment methods</a>, or <a
              (click)="goToSettings()">add a new one</a>.</p>
        </div>
        <div class="no-payments" *ngIf="methodsList?.length <= 0">
          <div id="no-payments">
            <p class="smaller desc">You’ll need to have a saved card for Auto Pay to work.</p>
            <p class="smaller">You can add one now on your Profile Settings page</p>
          </div>
          <div class="modal-actions">
            <button class="button primary" (click)="goToSettings()">Add Card</button>
          </div>
        </div>
        <div class="row form-section modal-actions" *ngIf="nonExpiredmethodsList?.length > 0">
          <p class="modal-hint">You can also add another payment method in <a (click)="goToSettings()">Profile
              Settings</a></p>
              <p class="validation-message modal-hint" *ngIf="!!displayRequiredMsg"><b>Select a payment method to proceed </b></p>
          <button class="button primary" data-cy="save" (click)="makePaymentMethodAsDefault(selectedMethodId)"
            [class.disabled]="selectedMethodId === context.userPlan.paymentMethodId || (setDefaultRequest)"
            [disabled]="selectedMethodId === context.userPlan.paymentMethodId || (setDefaultRequest)"
            [uiBlockButton]="setDefaultRequest">
            Save
          </button>
        </div>
      </div>
    </div>
  </div>
</div>